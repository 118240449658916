@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import '../../../../resources/sass/variables';
@import 'bootstrap/scss/variables';
@import "family.scss/source/src/family";
@import '../../../../resources/sass/snippets/menu-item';

@mixin mmenu-type1-color($modifier, $bg-color, $hover-bg-color, $text-color, $hover-text-color) {
	&--#{$modifier} > &__link {
		background-color: $bg-color;
		color: $text-color;
	}


	&#{&}--#{$modifier}:active > &__link,
	&#{&}--#{$modifier}:focus > &__link,
	&#{&}--#{$modifier}:hover > &__link,
	&#{&}--#{$modifier}.is-selected > &__link {
		background-color: $hover-bg-color;
		color: $hover-text-color;
	}
}

@mixin mmenu-type1-item-delimiter($modifier, $color) {
	&--#{$modifier} + &--#{$modifier}:after {
		content: '';
		position: absolute;
		width: 1px;
		height: 1.25rem;
		top: 50%;
		margin-top: -.63rem;
		left: -1px;
		background-color: $color;
		transition: $transition-base;
		opacity: 0.75;
	}

	&--#{$modifier} + &--#{$modifier}:active:after,
	&--#{$modifier} + &--#{$modifier}:focus:after,
	&--#{$modifier} + &--#{$modifier}:hover:after,
	&--#{$modifier}.is-selected:after,
	&--#{$modifier}.is-selected + &--#{$modifier}:after,
	&--#{$modifier}:active + &--#{$modifier}:after,
	&--#{$modifier}:focus + &--#{$modifier}:after,
	&--#{$modifier}:hover + &--#{$modifier}:after {
		opacity: 0;
	}
}

.l-mmenu-type1 {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	width: 100%;
	max-height: 3.75rem;
	z-index: 2;
	position: relative;

	&--inheader {
		z-index: $zindex-dropdown + 1;
		max-height: 2.8rem;
		overflow: hidden;
	}
}

.mmenu-type1-item {
	position: relative;

	&__link {
		padding: 1.13rem 1rem;
		height: 100%;
		max-height: 3.75rem;
		display: flex;
		align-items: center;
		font-weight: bold;
		white-space: nowrap;
		transition: $transition-base;
		letter-spacing: 0.2px;

		@include hover-focus-active {
			text-decoration: none;
		}
	}

	&__dropdown {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: $zindex-dropdown;
		display: none; // none by default, but block on "open" of the menu
		float: left;
		min-width: $dropdown-min-width;
		padding: $dropdown-padding-y 0;
		font-size: $font-size-base;
		color: $body-color;
		text-align: left;
		list-style: none;
		background-color: $dropdown-bg;
		background-clip: padding-box;
		box-shadow: 0 10px 32px 0 rgba(84, 133, 169, 0.3);
		min-width: 15.25rem;
	}

	&__wide {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: $zindex-dropdown;
		display: none;
		float: left;
		left: 0;
		z-index: $zindex-dropdown;
		min-height: 200px;
		background: theme-color('light');
		box-shadow: 0 10px 32px 0 rgba(84, 133, 169, 0.3);
		padding: 1.25rem;
	}

	&.is-ready:hover > &__dropdown,
	&.is-ready:hover > &__wide {
		display: block;
	}

	&__dropdown &__dropdown {
		left: 100%;
		top: 0;
		margin-top: -$dropdown-padding-y;
	}

	&__dropdown.is-invert &__dropdown {
		left: -100%;
	}

	&.is-more .icon-svg {
		font-size: 1.13rem;
	}

	&--dd-item > &__link {
		@extend %dropdown-menu-item-link;
		max-height: none;
	}

	&--dd-item:hover > &__link {
		@extend %dropdown-menu-item-link-hover;
	}

	&--dd-item.has-children > &__link:after {
		content: "";
		position: absolute;
		width: 0.8rem;
		height: 0.8rem;
		right: 1.25rem;
		top: 1.13rem;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+ICAgIDxwYXRoIGQ9Ik04LDE4YTEsMSwwLDAsMSwuMjktLjcxTDEzLjU5LDEyLDguMjksNi43MUExLDEsMCwwLDEsOS43MSw1LjI5bDYsNmExLDEsMCwwLDEsMCwxLjQybC02LDZhMSwxLDAsMCwxLTEuNDIsMEExLDEsMCwwLDEsOCwxOFoiLz48L3N2Zz4=");
	}

	@include mmenu-type1-color('primary', theme-color('primary'), $primary-hover, $primary-yiq, $primary-hover-yiq);
	@include mmenu-type1-color('dark', theme-color('dark'), $primary-hover, theme-color('light'), theme-color('light'));
	@include mmenu-type1-color('light', theme-color('light'), $primary-hover, $body-color, theme-color('light'));
	@include mmenu-type1-color('transparent', transparent, transparent,  $body-color, theme-color('primary'));
	@include mmenu-type1-color('inheader', transparent, transparent,  $body-color, theme-color('primary'));

	@include mmenu-type1-item-delimiter('dark', theme-color('light'));
	@include mmenu-type1-item-delimiter('primary', $primary-yiq);

	&--inheader > &__link {
		padding-top: .8rem;
		padding-bottom: .8rem;
	}

	@include media-breakpoint-up(lg) {
		&--inheader + &--inheader:after {
			content: '';
			position: absolute;
			width: 1px;
			height: 1.25rem;
			top: 50%;
			margin-top: -.63rem;
			left: -1px;
			background-color: $body-color;
			transition: $transition-base;
			opacity: 0.75;
		}
	}

	@include media-breakpoint-down(md) {
		&--inheader &__link {
			font-size: .88rem;
			padding-left: .5rem;
			padding-right: .5rem;
		}
	}
}

.mmenu-wide {
	display: flex;
	position: relative;

	&__items {
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 33.13rem; // 528px
		position: relative;
	}

	&__adv {
		display: none;
	}


	&__items::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	&__items::-webkit-scrollbar-track {
		background: rgba(0,0,0,0.1);
	}

	&__items::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,0.2);
	}

	&__items::-webkit-scrollbar-thumb:hover {
		background: rgba(0,0,0,0.4);
	}

	&__items::-webkit-scrollbar-thumb:active {
		background: rgba(0,0,0,.9);
	}

	@include media-breakpoint-up(lg) {
		&__adv {
			display: block;
			position: absolute;
			max-width: 20.63rem;
			right: .63rem;
		}

		&.has-image {
			margin-right: -.63rem;
		}

		&.has-image &__items {
			padding-right: 20.63rem;
			min-height: 33.13rem;
		}
	}
}

.mmenu-wide-item {
	margin-bottom: map-get($spacers, 6);
	max-width: 18.5rem;

	&__picture {
		max-width: 60%;
		display: block;
	}

	&__name {
		padding: 0.75rem 0 0 2.5rem;
		font-weight: 700;
		display: block;
		position: relative;
		max-width: 75%;
		line-height: 1.19rem;
		@include plain-hover-focus {
			color: $body-color;
		}
	}

	&__line {
		color: theme-color('primary');
	}

	&__line:before {
		content: "";
		position: absolute;
		width: 25px;
		left: 0;
		top: 10px;
		background: currentColor;
		height: 6px;
		border-radius: 10px;
	}

	&__count {
		color: $link-color;
	}

	&__sub {
		padding: 0.5rem 0 0 2.5rem;
		line-height: 1.38rem;
	}

	& & {
		padding-left: 0;
	}
}

.mmenu-wide-sub-item {
	font-size: 0.88rem;
}
