
@import '../../../../local/templates/megamart_default/resources/sass/system';

@import '../../../../local/templates/megamart_default/components/bitrix/menu/main_1/style.scss';

.l-head {
	position: relative;


	&__main {
		position: relative;
		z-index: 301;

		@include media-breakpoint-down(sm) {
			display: none;
		}
    }
}

.landing-main {
    @extend .l-head;
}

.mmenu-type1-item:after {
	@extend .mmenu-type1-item--primary:after;
}

.mmenu-type1-item__link {
    color: inherit !important;
}